import {EventAttachment, EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {
  EventTypeLabel,
  getEquipmentLabelDetails,
  ProcessStageTitle
} from '@hconnect/common/components/shiftEventLabels'
import {Tag} from '@hconnect/common/components/Tag'
import {MAX_ALLOWED_ATTACHMENTS, STOPPAGE_TYPE_TRANSLATION_KEYS} from '@hconnect/common/consts'
import {headlineSx, imageBoxSx} from '@hconnect/common/styles'
import {
  getMaintainAnalysisUrl,
  getStoppageDescription,
  getUniqueAttachments,
  isStoppageTypeWithReason
} from '@hconnect/common/utils'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {GridSetting} from '@hconnect/uikit'
import {CardBox, CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {MoreVert, DeleteOutlined, EditOutlined} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Divider, Grid, IconButton, Link, Menu, MenuItem, Typography} from '@mui/material'
import React, {FC, useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {useConfig} from '../../shared/hooks/configService'
import {getSalesImpactLabel} from '../helpers'
import {useProcessStages} from '../hooks/useProcessStages'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {StoppageEvent} from '../types'

import {DateTimePeriod} from './DateTimePeriod'

type StoppageDetailsProps = {
  stoppage: StoppageEvent
  onEdit: () => void
  onDelete: () => void
  onClose: () => void
}

const regularSize: GridSetting = {
  xs: 12,
  sm: 4,
  md: 6
}

type PathParameter = {
  plantId: string
  equipmentId: string
}

export const StoppageDetails: FC<StoppageDetailsProps> = ({
  stoppage,
  onEdit,
  onDelete,
  onClose
}) => {
  const menuButtonRef = useRef(null)
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')

  const [activeAttachmentId, setActiveAttachmentId] = useState<number | undefined>(undefined)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen])
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen])

  const {data} = useProcessStages(plantId)
  const {data: config} = useConfig(plantId)

  const onDeleteClick = () => {
    closeMenu()
    onDelete()
  }

  const uniqueAttachments = getUniqueAttachments(stoppage.attachments, MAX_ALLOWED_ATTACHMENTS)

  return (
    <CardBox data-test-id="stoppage-details">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h3" data-test-id="stoppage-details-title">
          {stoppage.title}
        </Typography>

        <Box>
          <IconButton
            sx={{p: 1}}
            ref={menuButtonRef}
            onClick={openMenu}
            color="primary"
            data-test-id="stoppage-details-more-button"
          >
            <MoreVert />
          </IconButton>
          <Menu
            data-test-id="stoppage-details-more-menu"
            anchorEl={menuButtonRef.current}
            open={menuOpen}
            onClose={closeMenu}
          >
            <MenuItem onClick={onEdit} data-test-id="stoppage-details-edit-button">
              <Typography variant="subtitle1">
                <EditOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
                {t(`${performancePrefix}.plantStatus.action.edit`)}
              </Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={onDeleteClick} data-test-id="stoppage-details-delete-button">
              <Typography variant="subtitle1">
                <DeleteOutlined sx={{mr: 2, verticalAlign: 'text-bottom'}} />
                {t(`${performancePrefix}.plantStatus.action.delete`)}
              </Typography>
            </MenuItem>
          </Menu>
          <IconButton
            sx={{p: 1}}
            onClick={onClose}
            color="primary"
            data-test-id="stoppage-details-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Grid container my={1.5} spacing={2}>
        <Grid item xs={12}>
          <Tag labelKey="shiftEvent.label.stoppage" translationPrefix={performancePrefix}>
            <EventTypeLabel eventType={stoppage.eventType} translationPrefix={performancePrefix} />
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag
            labelKey="shiftEvent.label.stoppageType"
            translationPrefix={performancePrefix}
            data-test-id="stoppage-details-type"
          >
            {t(
              getTranslationKey(
                STOPPAGE_TYPE_TRANSLATION_KEYS[stoppage.stoppageType],
                performancePrefix
              )
            )}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.salesImpact"
            translationPrefix={performancePrefix}
            data-test-id="stoppage-details-sales-impact"
          >
            {getSalesImpactLabel(stoppage.hasSalesImpact)}
          </Tag>
        </Grid>
        {/* HCP-42934 uncomment when management decides*/}
        {/* <Grid item {...regularSize}>*/}
        {/*  <Tag labelKey="shiftEvent.label.cost" data-test-id="stoppage-details-cost">*/}
        {/*    {stoppage.cost ? `${stoppage.cost} ${stoppage.costCurrency}` : '-'}*/}
        {/*  </Tag>*/}
        {/* </Grid>*/}
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.stoppageCode"
            translationPrefix={performancePrefix}
            data-test-id="stoppage-details-code"
          >
            {'stoppageCode' in stoppage && config
              ? `${stoppage.stoppageCode} ${getStoppageDescription(stoppage, {
                  kiln: config.kilnStoppageCodes,
                  cementMill: config.finishMillStoppageCodes,
                  rawMill: config.rawMillStoppageCodes
                })}`
              : '-'}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.timeframe"
            translationPrefix={performancePrefix}
            data-test-id="stoppage-details-timeframe"
          >
            <DateTimePeriod start={stoppage.stoppageStart} end={stoppage.stoppageEnd} />
          </Tag>
        </Grid>
        {isStoppageTypeWithReason(stoppage.stoppageType) && 'stoppageReason' in stoppage && (
          <Grid item xs={12}>
            <Tag
              labelKey="shiftEvent.label.stoppageReason"
              translationPrefix={performancePrefix}
              data-test-id="event-detail-stoppageReason"
            >
              {stoppage.stoppageReason}
            </Tag>
          </Grid>
        )}
        <Grid item xs={12}>
          <Tag
            data-test-id="event-detail-equipment"
            translationPrefix={performancePrefix}
            labelKey="shiftEvent.label.equipment"
          >
            {getEquipmentLabelDetails(stoppage.equipment)}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag
            data-test-id="event-detail-main-equipment"
            translationPrefix={performancePrefix}
            labelKey="shiftEvent.label.mainEquipment"
          >
            {getEquipmentLabelDetails(stoppage.mainEquipment, false)}
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.processStage"
            translationPrefix={performancePrefix}
            data-test-id="event-detail-processStage"
          >
            {!stoppage.processStage ? (
              ' - '
            ) : (
              <ProcessStageTitle stages={data} stage={stoppage.processStage} />
            )}
          </Tag>
        </Grid>
        <Grid item xs={12}>
          <Tag labelKey="shiftEvent.label.description" translationPrefix={performancePrefix}>
            {stoppage.description}
          </Tag>
        </Grid>

        {stoppage.rootCauseFailureAnalyses && (
          <Grid item xs={12}>
            <Tag
              labelKey="shiftEvent.label.rootCauseFailureAnalyses"
              translationPrefix={performancePrefix}
              data-test-id="stoppage-detail-rootCauseFailureAnalyses"
            >
              <Box display="flex" flexDirection="column">
                {stoppage.rootCauseFailureAnalyses.map(({title, id}) => (
                  <Link
                    key={id}
                    href={getMaintainAnalysisUrl(plantId, id)}
                    underline="none"
                    fontWeight={600}
                    width="fit-content"
                  >
                    {title}
                  </Link>
                ))}
              </Box>
            </Tag>
          </Grid>
        )}
      </Grid>

      <CardSectionTitle sx={headlineSx()}>
        {t(`${performancePrefix}.shiftEvent.attachments`)}{' '}
        {`${uniqueAttachments.length}/${MAX_ALLOWED_ATTACHMENTS}`}
      </CardSectionTitle>
      <Divider sx={{borderColor: 'common.black'}} />
      <Box component={'span'} sx={imageBoxSx()}>
        {uniqueAttachments.map((attachment) => (
          <EventAttachment
            key={`attachment-${attachment.id}`}
            attachment={attachment}
            setActiveAttachmentId={setActiveAttachmentId}
          />
        ))}
      </Box>

      {typeof activeAttachmentId === 'number' && uniqueAttachments && (
        <EventAttachmentsDisplay
          attachments={uniqueAttachments}
          activeAttachmentId={activeAttachmentId}
          onClose={() => setActiveAttachmentId(undefined)}
        />
      )}
    </CardBox>
  )
}
