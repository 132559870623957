import {DateFormat} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {memo} from 'react'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'

import {TimeFormat} from './charts'

type DateTimePeriodProps = {
  start: string
  end?: string
}

export const DateTimePeriod = memo<DateTimePeriodProps>(({start, end}) => {
  const timezone = getSystemTimezone()
  const isDateSame = moment(start).isSame(end, 'day')

  return (
    <>
      <DateFormat date={start} timezone={timezone} />{' '}
      <TimeFormat date={start} timezone={timezone} />
      {end && (
        <>
          {' - '}
          {!isDateSame && (
            <>
              <DateFormat date={end} timezone={timezone} />{' '}
            </>
          )}
          <TimeFormat date={end} timezone={timezone} />
        </>
      )}
    </>
  )
})
